<template>
  <section class="m-wallet m-scroll">
    <!-- 头部导航 -->
    <div class="header">
      <img @click="$router.go(-1)" src="@/assets/icon_back@2x.png" alt="" />
      <p>Your wallet</p>
    </div>
    <div class="header-bg"></div>

    <!-- 钱包金额 -->
    <div class="money">
      <div class="sum flex-item">
        <p>Total balance($)</p>
        <p>{{ detail.amount }}</p>
      </div>
      <div class="income">
        <div class="flex-item">
          <p>Withdraw($)</p>
          <p>{{ detail.withdrawal_amount }}</p>
        </div>
        <div class="line"></div>
        <div class="flex-item">
          <p>Not withdrawable($)</p>
          <p>{{ detail.non_withdrawal_amount }}</p>
        </div>
      </div>
    </div>

    <!-- 申请提现 -->
    <div class="btn-wrap">
      <van-button
        round
        block
        type="info"
        native-type="submit"
        color="linear-gradient(315deg,rgba(240,100,72,1) 0%, rgba(240,60,24,1) 100%)"
        @click="recharge"
        >Recharge</van-button>
      <!-- <van-button
        :to="{
          path: '/zh/wallet/cash',
          query: { m: detail.withdrawal_amount },
        }"
        round
        block
        type="info"
        native-type="submit"
        color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)"
        >Withdrawal</van-button
      > -->
    </div>

    <!-- 服务 -->
    <div class="service">
      <!-- <router-link to="/en/wallet/cardpackage" class="service-item">
        <div>
          <img src="@/assets/wallet/icon_kabao@2x.png" alt="" />
          <span>My card</span>
        </div>
        <img src="@/assets/payment/icon-fanhui@2x.png" alt="" />
      </router-link> -->
      <router-link to="/en/wallet/record" class="service-item">
        <div>
          <img src="@/assets/wallet/icon_tixian@2x.png" alt="" />
          <span>Withdrawals record</span>
        </div>
        <img src="@/assets/payment/icon-fanhui@2x.png" alt="" />
      </router-link>
    </div>
  </section>
</template>

<script>
import { wallet } from "@/api/zh/mine.js";

export default {
  name: "Wallet",
  data() {
    return {
      detail: {
        amount: "0.00",
        non_withdrawal_amount: "0.00",
        total_amount: "0.00",
        withdrawal_amount: "0.00",
      },
    };
  },

  methods: {
    handleBack() {
      this.$router.go(-1);
    },

    getData() {
      wallet().then((res) => {
        if (res) {
          this.detail = res.data;
        }
      });
    },
    recharge() {
      this.$notify({ type: 'warning', duration: 2000, message: 'Top Up Not Available !' })
    }
  },

  created() {
    this.getData();
  },
};
</script>

<style lang="less" scoped>
@import "./wallet.less";
</style>